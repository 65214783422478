@import "../../styles/global.scss";

.title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 4px;
}

.message {
  text-align: center;
}

.icon_size {
  font-size: 26px;
}

.avatar_container {
  position: absolute;
  top: -96px;
  left: 45%;
}

.avatar {
  color: $white_color;
  background-color: $error_color;
}

.positioner {
  margin-top: 36px;
  position: relative;
}
