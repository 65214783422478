@import "../../styles/global.scss";

.go_back {
  font-weight: 500;
  font-style: italic;
  text-decoration: underline;
  cursor: pointer;
}

.services_listing {
  margin: 20px;
}

.services_spinner {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.card {
  margin-bottom: 10px;
}

.ant-card-cover {
  text-align: -webkit-center;
}

.ant_card_meta {
  div {
    text-align: -webkit-center;
    color: #225997;
    justify-content: center;
  }
}

.image {
  width: 100px;
  height: 80px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.link_manual {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 64px;
  padding-right: 20px;
}
