.users_listing {
  display: flex;
  justify-content: center;
  margin: 16px 16px 0 0;
}

.table {
  width: 100%;
  margin-right: 10px;
}
