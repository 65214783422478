@import "../../../../styles/global.scss";

.paragraph {
  font-size: 16px;
  font-weight: bold;
  margin: 0px;
}

.row_margin_top {
  margin-top: 20px;
}

.div_padding {
  padding: 4px;
}

.delete_outlined_color {
  color: #ff0000;
}
