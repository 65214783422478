@import "../../../styles/global.scss";

.go_back {
  font-weight: 500;
  font-size: 18px;
  font-style: italic;
  text-decoration: underline;
  cursor: pointer;
}

.title {
  font-weight: 600;
  font-size: 24px;
}

.container {
  width: calc(100% - 36px);
  padding: 16px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
}

.image_container {
  display: flex;
  margin-top: 0.8rem;
  gap: 1rem;
}
