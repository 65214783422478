@import "../../../styles/global.scss";

.space_align_block {
  margin: 0 4px 0 0;
}

.space_align_document {
  margin: 20px 0;
}

.custom_form_padding {
  div:first-child {
    padding: 0;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
}

.plus_button {
  margin-left: 1rem;
}
