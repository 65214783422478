@import "../../../../styles/global.scss";

.data_placeholder_input {
  div {
    margin-left: 0.5rem;
  }
}

.custom_form_padding {
  div:first-child {
    padding: 0;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
}

.textarea_input {
  resize: none;
}
