@import "../../../styles/global.scss";

strong {
  margin-right: 8px;
}

.services_spinner {
  display: flex;
  justify-content: flex-start;
  margin: 10px;
}
