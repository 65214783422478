@import "../../../styles/global.scss";

.dropzone {
  border: 2px #ccc dashed;
  margin: 0;
  padding: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  flex-direction: column;
}
