@import "../../../styles/global.scss";

.custom_form_padding {
  div:first-child {
    padding: 0;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
}

.mt_12 {
  margin-top: 12px;
}

.msg_error {
  color: $error_color;
}

.services_spinner {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.cron_label {
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  margin: 0;
}
