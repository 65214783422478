@import "../../../styles/global.scss";

.table {
  width: 100%;
  margin-right: 10px;
}

.custom_form_padding {
  div:first-child {
    padding: 0;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
}

.align_row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.5rem;
  gap: 0.5rem;
  p {
    font-weight: bold;
    margin: 0;
  }
}

.link_manual {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
