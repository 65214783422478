@import "../../styles/global.scss";

.center_layout {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content_center {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 8px;
  align-items: center;
  justify-content: center;
}

.container {
  max-width: 600px;
  img {
    width: 400px;
    height: auto;
  }
}
