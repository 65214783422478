@import "../../../../styles/global.scss";

.plus_button {
  margin: 10px;
  align-items: flex-end;
}

.card_modal {
  margin: 0 auto;
  height: 50%;
  justify-content: center;
}

.container_center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 720px;
}
