@import "../../../styles/global.scss";

.custom_form_padding {
  div:first-child {
    padding: 0;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
}

.container_icon_Remove_form {
  display: flex;
  margin-top: 8px;
  justify-content: center;
}
