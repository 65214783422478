@import "../../styles/global.scss";

.site_layout_sub_header {
  background: #f0f2f5;
  padding: 0px;
}

.container_about {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  margin-left: 14px;
}

.icon_container_about {
  display: flex;
  align-items: center;
  justify-content: center;
}
.site_page_content {
  margin-left: 40px;
  overflow-y: auto;
}

.sidebar_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.tx_about {
  color: $menu_color;
  font-size: 16px;
  line-height: 1.4;
}

.logo {
  width: 20px;
  height: 20px;
}

.container_profile {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 24px;
}

.icon {
  color: #ffffffa6;
}

.footer_font_size {
  font-size: 16px;
  margin: 0;
}

.layout_height {
  min-height: 100vh;
  height: 100%;
}

.sider {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
}

.typography_title {
  color: #f2f2f3 !important;
  display: flex;
  flex: 1;
  margin-bottom: 0 !important;
  justify-content: center;
}

.menu_outlined {
  color: #f2f2f3;
  font-size: 20px;
}

.page_menu_padding {
  padding: 0 8px;
}

.layout_margin_left {
  margin-left: 200px;
}

.menu_font_size {
  margin-top: 100px;
  font-size: 16px;
}

.divider {
  background-color: white;
  margin-bottom: 0px;
}
