@import "../../styles/global.scss";

.templates_button {
  text-align: right;
  margin: 24px 0 16px;
}

.token_button {
  margin-bottom: 20px;
}

.services_spinner {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.mr_40 {
  margin-right: 40px;
}

.color_red {
  color: $error_color;
}
