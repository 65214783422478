.container {
  background-color: white;
  padding: 15px;
  flex: 1 1 auto;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.row_margin {
  margin-bottom: 25px;
  margin-right: 50px !important;
}

.stretch {
  margin-bottom: 25px;
  margin-right: 50px !important;
  align-items: stretch;
}

.description {
  text-align: left;
  font-style: italic;
  font-size: 16px;
  letter-spacing: 0px;
  color: #878889;
}

.italic {
  font-style: italic;
}
