@import "../../styles/global.scss";

body {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue";
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.login_page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #0081c9;
}

.login_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  max-width: 1000px;
  background-color: white;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  margin: 0 auto;
  border-radius: 12px;
}

.login_form {
  flex: 1 0 100%;
  max-width: 480px;
  width: 100%;
  padding: 60px;

  p + p {
    margin-bottom: 30px;
  }

  .ant-form-item-label > label.ant-form-item-required::before {
    display: none;
  }

  .ant-input-affix-wrapper {
    padding: 12px 15px;
  }
}

.form_title {
  color: #333333;
  font-size: 34px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 4px;
}

.ant-form-item-control-input-content {
  text-align: left;
}

.login_form_username {
  height: 48px;
}

.login_form_button {
  width: 100%;
  height: 42px;
  letter-spacing: 1px;
  border-radius: 6px;
}

.illustration_wrapper {
  display: flex;
  align-items: flex-end;
  max-width: 800px;
  min-height: 100%;
  background-color: #fffdf2;
}

.illustration_wrapper img {
  display: block;
  width: 100%;
}

@media screen and (max-width: 1023px) {
  .login_box {
    flex-direction: column;
    box-shadow: none;
  }
  .illustration_wrapper {
    max-width: 100%;
    min-height: auto;
  }
  .login_form {
    max-width: 100%;
  }
}
