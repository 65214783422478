@import "../../styles/global.scss";

.container_external_links {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  margin-top: 8px;
}

.go_back {
  font-size: 20px;
  font-weight: 500;
  font-style: italic;
  text-decoration: underline;
  cursor: pointer;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 200px);
}

.spin_height {
  height: 700px;
  overflow-y: auto;
}

.title_margin {
  margin-left: 20px;
  margin-right: 20px;
}

.table_margin {
  margin-right: 40px;
}

.delete_icon_color {
  color: #ff0000;
}
