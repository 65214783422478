.custom_form_padding {
  div:first-child {
    padding: 0;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
}

.container_align_right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 24px;
}

.data_placeholder_input {
  div {
    margin-left: 0.5rem;
  }
}
