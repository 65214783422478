@import "../../../styles/global.scss";

.custom_form_padding {
  div:first-child {
    padding: 0;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
}

.checkbox_group {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 24px;
}

.tabs_align_left {
  text-align: left;
}

.data_placeholder_input {
  div {
    margin-left: 0.5rem;
  }
}
