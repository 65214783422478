$primary_color: #1890ff;
$secondary_color: #c6147f;
$success_color: #4caf50;
$error_color: #ef5350;
$text_primary: #212121;
$text_secondary: #878889;
$white_color: #f2f2f3;
$menu_color: #ffffffa6;

$medium: 1199px;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: #aaa;
}

::-webkit-scrollbar-thumb {
  background-color: #4767c7;
  border-radius: 8px;
}

.ant-table-row {
  background-color: #fff;
}

button {
  .ant-spin {
    padding-right: 10px;
  }
}

.ant-btn-primary {
  border-radius: 5px;
}

.ant-btn-default {
  border-radius: 5px;
}

// Modal

.ant-modal-content {
  border-radius: 8px;
}

.ant-modal-header {
  border-radius: 8px 8px 0 0;
}

.ant-modal-title {
  font-size: 24px;
  letter-spacing: 0.24px;
  text-align: initial;
}

.ant-modal-body {
  padding: 16px;
}

.ant-modal-footer {
  border: none;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: "";
}

.ant-picker {
  width: 100%;
  height: 30px;
}

.ant-picker-suffix {
  margin: 0 8px 0;
}

.ant-page-header-heading-title {
  font-size: 40px;
  line-height: 1.2;
  letter-spacing: 0.4px;
  margin-bottom: 8px;
  line-height: 1.2;
}

.ant-page-header-heading-sub-title {
  font-size: 20px;
  letter-spacing: 0;
}

.ant-page-header-heading-left {
  flex-direction: column;
  align-items: flex-start;
}

.ant-page-header {
  padding: 16px 40px 4px;
}

.ant-tabs {
  font-size: 16px;
}
