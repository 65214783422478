@import "../../styles/global.scss";

.tokens_listing {
  margin: 16px 16px 0 0;
}

.token_button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 18px;
}

.token_modal {
  width: 100%;
}

.services_spinner {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.image {
  width: 20px;
}

.deleted_color {
  color: #ff0000;
}
