.button_width {
  width: 90px;
}

.container_padding {
  padding: 8px;
}

.input_margin {
  margin-bottom: 8px;
  display: block;
}
