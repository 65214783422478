@import "../../styles/global.scss";

.table {
  width: 100%;
  margin-right: 10px;
}

.container_align_right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 24px;
}

.settings_listing {
  display: flex;
  justify-content: center;
  margin: 16px 16px 0 0;
}

.search_bar {
  margin-top: 16px;
  max-width: 300px;
}

.link_manual {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 64px;
  padding-right: 20px;
}

.services_spinner {
  display: flex;
  justify-content: center;
  margin: 20px;
}
