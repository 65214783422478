@import "../../styles/global.scss";

.space_align_container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 40px;
}

.documents_button {
  text-align: right;
  margin: 24px 0 16px;
}

.token_button {
  margin-bottom: 20px;
}

.services_spinner {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.table {
  width: 100%;
  margin-right: 10px;
}

.deleted_color {
  color: #ff0000;
}

.ant-modal-body {
  text-align: center;
}
